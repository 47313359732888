html,
body,
#root {
  height: 100%;
}

#root {
  display: flex !important;
  flex-direction: column !important;
}

h6,
.h6 {
  font-size: 1.125rem;
}

.fw-500 {
  font-weight: 500;
}

.pe-pointer {
  cursor: pointer;
}

.fs-7 {
  font-size: 0.7rem !important;
}

.display-7 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
  line-height: 1.2;
}

a.h5-link {
  color: inherit;
}

@import "./theme.scss";
