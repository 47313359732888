@import "./node_modules/bootstrap/scss/_functions.scss";
@import "./node_modules/bootstrap/scss/_variables.scss";

$intouch-blue: #015b90;
$intouch-blue-dark: #012539;
$intouch-green: #01a578;

// $intouch-teal: #0B7B97;
// $intouch-gray: $gray-300;

.text-intouch-blue {
  color: $intouch-blue !important;
}
.text-intouch-blue-dark {
  color: $intouch-blue-dark !important;
}
.text-intouch-green {
  color: $intouch-green !important;
}

.border-intouch-blue {
  border-color: $intouch-blue !important;
}
.border-intouch-blue-dark {
  border-color: $intouch-blue-dark !important;
}
.border-intouch-green {
  border-color: $intouch-green !important;
}

.bg-intouch-blue {
  background-color: $intouch-blue !important;
}
.bg-intouch-blue-dark {
  background-color: $intouch-blue-dark !important;
}
.bg-intouch-green {
  background-color: $intouch-green !important;
}

// .bg-intouch-teal {
//   background-color: $intouch-teal !important;
// }
// .bg-intouch-gray {
//   background-color: $intouch-gray !important;
// }
