body {
  overflow-y: auto;
}

.print {
  display: none;
}

#document {
  border-color: none !important;

  .document-body {
    border-color: none !important;
    background-color: #fff;
  }
}

@media print {
  @page {
    size: auto;
  }

  #root {
    display: none;
  }

  body {
    overflow: visible;
  }

  .print {
    display: unset !important;
  }

  .print-no-margin {
    margin: 0 !important;
  }

  #document,
  #document .document-body {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
