footer .nav-white .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

footer .nav-white .nav-link:not(.active):hover {
  color: #fff;
}

footer .nav-x-0 .nav-link {
  padding: 0.2rem 0;
}

footer .nav-sm .nav-link {
  font-size: 0.875rem;
}

footer .text-sm {
  font-size: 0.875rem;
}

footer .text-muted-light {
  color: rgba(255, 255, 255, 0.7);
}
