@import "./theme.scss";

.icon-link-button {
  &:hover {
    background-color: $gray-100;
  }
}

.icon-link {
  color: $gray-900 !important;
}
