@media (max-width: 575px) {
  .hero {
    height: 200px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hero {
    height: 200px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero {
    height: 300px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hero {
    height: 400px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .hero {
    height: 500px !important;
    background-position: center 37% !important;
  }
}

@media (min-width: 1400px) {
  .hero {
    height: 525px !important;
    background-position: center 37% !important;
  }
}